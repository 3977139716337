<div style="margin: 0; padding: 0">
  <div class="row" style="margin: 0; padding: 0">
    <div class="col-12 col-md-6">
      <div class="home-info">
        <div>
          <h3>SOMOS DIGITALIZACIÓN</h3>
          <h1>¡Transformación Digital para todos!</h1>

          <div class="parraf-info">
            <p>
              GoDigital es tu partner para que tus negocios sean Digital-first y
              alcance su máximo potencial, optimizando tus procesos internos y
              sobre todo la experiencia de tus clientes.
            </p>
          </div>
          <div class="btn-more">
            <button [routerLink]="['/about']">DESCUBRE MÁS</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 home-img" style="margin: 0; padding: 0">
      <div class="home-info-imgs animate__animated animate__bounceInDown">
        <img src="../../../assets/images/vectores/Vector 1-mod.png" alt="" />
      </div>
    </div>
  </div>
</div>

<app-slider></app-slider>

<div class="cont">
  <div class="row cont">
    <div class="clients">
      <h1>Marcas que han confiado en nosotros</h1>
      <div class="cont-sw">
        <swiper
          [autoplay]="true"
          [loop]="true"
          [navigation]="true"
          [effect]="'slide'"
          [grabCursor]="true"
          [centeredSlides]="true"
          [slidesPerView]="elementPerView"
          [pagination]="false"
          class="mySwiper"
        >
          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/CCervezeraLogo.png"
              alt="Compañia Cervezera de Nicaragua"
              class="hero-client-logo"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/barrioCafeLogo.png"
              alt="Barrio Café"
              class="hero-client-logo"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/podLogo.png"
              alt="POD Logo"
              class="hero-client-logo"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/ranchitoLogo.png"
              alt="Mi Viejo Ranchito"
              class="hero-client-logo"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/zocaloLogo.jpg"
              alt="El Zocalo"
              class="hero-client-logo"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/Contentera.png"
              alt="Contentera"
              class="hero-client-logo"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/DFManagua.png"
              alt="DF Managua"
              class="hero-client-logo"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/HEMCO.png"
              alt="HEMCO logo"
              class="hero-client-logo hemco"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/SparkHardSeltzerLogo.webp"
              alt="Spark Hard Seltzer"
              class="hero-client-logo"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/SINSA.webp"
              alt="SINSA"
              class="hero-client-logo sinsa"
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              src="../../../assets/images/clients/tonaUltra.webp"
              alt="SINSA"
              class="hero-client-logo tonaUltra"
            />
          </ng-template>
        </swiper>
      </div>
    </div>
  </div>
</div>

<div class="cont">
  <app-contact-in-pages></app-contact-in-pages>
</div>
